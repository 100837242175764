:root {
  --alert: #ffd200;
  --azul: #2348ae;
  --branco: #fff;
  --card-shadow: 0px 18px 50px -15px #192a5933;
  --cinza-claro: #f2f2f2;
  --cinza-escuro: #646464;
  --cinza-mdio: #e6e6e6;
  --cinza-mdio-2: #a7a7a7;
  --creme: #f4f3dc;
  --laranja: #ea7526;
  --preto: #1f1f1f;
  --pretohalf: #1f1f1f80;
  --rosa: #f9d8d8;
  --text-main: #172755;
  --text-secondary: #8794ba;
  --verde: #3cfd79;
  --verde-escuro: #163e4c;
  --white: #fff;
}
/*# sourceMappingURL=index.009bc15a.css.map */
