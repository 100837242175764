:root {
  --alert: rgba(255, 210, 0, 1);
  --azul: rgba(35, 72, 174, 1);
  --branco: rgba(255, 255, 255, 1);
  --card-shadow: 0px 18px 50px -15px rgba(25, 42, 89, 0.2);
  --cinza-claro: rgba(242, 242, 242, 1);
  --cinza-escuro: rgba(100, 100, 100, 1);
  --cinza-mdio: rgba(230, 230, 230, 1);
  --cinza-mdio-2: rgba(167, 167, 167, 1);
  --creme: rgba(244, 243, 220, 1);
  --laranja: rgba(234, 117, 38, 1);
  --preto: rgba(31, 31, 31, 1);
  --pretohalf: rgba(31, 31, 31, 0.5);
  --rosa: rgba(249, 216, 216, 1);
  --text-main: rgba(23, 39, 85, 1);
  --text-secondary: rgba(135, 148, 186, 1);
  --verde: rgba(60, 253, 121, 1);
  --verde-escuro: rgba(22, 62, 76, 1);
  --white: rgba(255, 255, 255, 1);
}
